import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../stylesheets/team.css';

const Slideshow = ({ employees, additional, textalign }) => {
  const [hoveredEmployee, setHoveredEmployee] = useState(employees[0]);
  const [startIndex, setStartIndex] = useState(0);
  const [displayCount, setDisplayCount] = useState(4);  // Number of employees per slide
  const slidesRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 821) {
        setDisplayCount(3); // 3 items per slide on smaller screens
      } if (window.innerWidth < 768) {
        setDisplayCount(2); // 2 items per slide on very small screens
      } else {
        setDisplayCount(4); // 4 items per slide on larger screens
      }
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Auto-scroll functionality
  useEffect(() => {
    const autoScrollInterval = setInterval(() => {
      setStartIndex((prevStartIndex) => (prevStartIndex + 1) % Math.ceil(employees.length / displayCount));
    }, 8000); // Slide every 8 seconds

    return () => clearInterval(autoScrollInterval);
  }, [displayCount, employees.length]);

  const handlePageClick = (pageIndex) => {
    setStartIndex(pageIndex); // Set startIndex based on page click
  };

  const totalPages = Math.ceil(employees.length / displayCount); // Calculate total pages

  return (
    <div className="employee-slideshow">
      <div
        className="slides-container w-[80%] mx-auto overflow-hidden relative"
        ref={slidesRef}
      >
        {/* Grid layout to show employees per page */}
        <div
          className="flex transition-transform ease-in-out transform"
          style={{
            // transform: `translateX(-${startIndex * 100}%)`,
            display: 'grid',
            gridTemplateColumns: `repeat(${displayCount}, 1fr)`, // Adjust grid columns based on displayCount
            gap: '1rem',
          }}
        >
          {/* Display only employees for the current page */}
          {employees.slice(startIndex * displayCount, (startIndex + 1) * displayCount).map((employee, index) => (
            <div key={index} className={`${additional} slide flex-shrink-0`}>
              {employee.link ? (
                <Link to={employee.link} target="_blank" rel="noopener noreferrer">
                  <img
                    src={employee.image}
                    alt={employee.name}
                    className="employee-image rounded-lg object-cover w-full lg:h-64 max-lg:h-30"
                    onMouseEnter={() => setHoveredEmployee(employee)}
                  />
                  <div className={`employee-info ${textalign} mt-4`}>
                    <div className="employee-name font-bold text-lg max-sm:text-sm">{employee.name}</div>
                    <div className="employee-role text-sm">{employee.role}</div>
                  </div>
                </Link>
              ) : (
                <>
                  <img
                    src={employee.image}
                    alt={employee.name}
                    className="employee-image rounded-lg object-cover w-full h-72"
                    onMouseEnter={() => setHoveredEmployee(employee)}
                  />
                  <div className={`employee-info ${textalign} mt-4`}>
                    <div className="employee-name font-bold text-lg">{employee.name}</div>
                    <div className="employee-role text-sm">{employee.role}</div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Pagination buttons */}
      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }).map((_, pageIndex) => (
          <button
            key={pageIndex}
            className={`page-dot w-10 h-6 mx-1 rounded-[30px] border border-black-500 ${pageIndex === startIndex ? 'bg-red-500 border-red-500' : 'bg-white'}`}
            onClick={() => handlePageClick(pageIndex)}
          ></button>
        ))}
      </div>

      {/* Testimonial display */}
      {hoveredEmployee.testimonial ? (
        <div className="testimonial bg-gray-700 text-white max-sm:px-4 px-40 pt-8 mt-4 pb-[12%] mb-[-10%]">
          <h2 className="text-xl md:text-4xl font-bold" style={{ color: "#C30D3F" }}>{hoveredEmployee.name}</h2>
          <p className="text-sm md:text-base w-[80%]">{hoveredEmployee.testimonial}</p>
        </div>
      ) : null}
    </div>
  );
};

export default Slideshow;
